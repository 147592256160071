import {ReactNode, useMemo} from 'react';
import {Route} from 'react-router-dom';

import {SeoObject, SsrContextData} from '@shared/frontends/ssr_context';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {withSeo} from '@shared-frontend/components/core/with_seo';
import {withTracker} from '@shared-frontend/components/core/with_tracker';
import {ComponentClass, wrapElements} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

export interface RouteOpts {
  seo: SeoObject;
  useTracker?: boolean;
  wrapper?: ComponentClass | ComponentClass[];
  isDisabledHandler?: (opts: ShouldRenderOpts) => ComponentClass | undefined;
}

interface ShouldRenderOpts {
  session?: ReturnType<typeof useSession>;
  ssrContext: SsrContextData;
}

// Handler for determining if a route should be rendered a 404 based on the session
export const sessionRequired =
  (c: ComponentClass) =>
  (opts: ShouldRenderOpts): ComponentClass | undefined =>
    opts.session === undefined ? c : undefined;

// Same for super admin
export const superAdminSessionRequired =
  (c: ComponentClass) =>
  (opts: ShouldRenderOpts): ComponentClass | undefined =>
    opts.session?.isAdmin === undefined ? c : undefined;

// Same for cse admin
export const cseAdminSessionRequired =
  (c: ComponentClass) =>
  (opts: ShouldRenderOpts): ComponentClass | undefined =>
    // TODO: add cse company id for admin rights
    opts.session?.isAdmin === undefined ? c : undefined;

export const useRoute = (path: string, component: ComponentClass, opts: RouteOpts): ReactNode => {
  const {seo, useTracker, wrapper = [], isDisabledHandler = () => undefined} = opts;

  // Determine if the route should be rendered
  const session = useSession();
  const ssrContext = useSsrContext();
  const DisabledComponent = useMemo(() => {
    return isDisabledHandler({session, ssrContext});
  }, [isDisabledHandler, session, ssrContext]);

  // Wrap the component with the wrapper classes
  const WrappedClass = useMemo(() => {
    const classes = !DisabledComponent
      ? [...(Array.isArray(wrapper) ? wrapper : [wrapper]), component]
      : [DisabledComponent];
    const element = wrapElements(classes);
    return () => element;
  }, [DisabledComponent, component, wrapper]);

  // Wrap the component with SEO and Tracker
  const RouteComponent = useMemo(() => {
    const Component = withSeo(WrappedClass, seo);
    return useTracker ? withTracker(Component) : Component;
  }, [WrappedClass, seo, useTracker]);

  // Return the Route component
  return <Route key={path} path={path} Component={RouteComponent} />;
};
