import {FC} from 'react';
import {Routes} from 'react-router';

import {useRoute} from '@shared-frontend/components/core/use_route';

import {AppWrapper} from '@src/components/app_wrapper';
import {CivPage} from '@src/components/civ_page';
import {DetailsPage} from '@src/components/details_page';
import {UnitsPage} from '@src/components/units_page';

export const App: FC = () => {
  const seo = {
    title: 'AoE2',
    description: 'Compare AoE2 civilizations',
  };

  return (
    <Routes>
      {useRoute('/:firstCiv/:secondCiv', CivPage, {seo, wrapper: AppWrapper})}
      {useRoute('/details/:encodedPath', DetailsPage, {seo, wrapper: AppWrapper})}
      {useRoute('/units', UnitsPage, {seo, wrapper: AppWrapper})}
      {useRoute('*', CivPage, {seo, wrapper: AppWrapper})}
    </Routes>
  );
};
App.displayName = 'App';
