import {FC, useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {SeoObject} from '@shared/frontends/ssr_context';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {ComponentClass} from '@shared-frontend/lib/react';

export const withSeo = (WrappedComponent: ComponentClass, seo: SeoObject): ComponentClass => {
  const ComponentWithSeo: FC = () => {
    const {pathname, hash, key} = useLocation();
    const {setSeo} = useSsrContext();

    const updateSeo = useCallback(() => setSeo(seo), [setSeo]);

    useEffect(updateSeo, [updateSeo]);
    useEffect(() => {
      // Update SEO on page change
      updateSeo();
      // if not a hash link, scroll to top
      if (hash === '') {
        document.body.scrollTo({behavior: 'instant', left: 0, top: 0});
      }
      // else scroll to id
      else {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
          }
        }, 0);
      }
    }, [pathname, hash, key, updateSeo]);

    // if (!session && options?.sessionRequired) {
    //   return <Navigate to="/login" state={{from: pathname}} replace />;
    // }
    return <WrappedComponent />;
  };
  ComponentWithSeo.displayName = `useRoute`;
  return ComponentWithSeo;
};
