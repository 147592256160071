import {ComponentType, FC, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {PathRouteProps} from 'react-router-dom';

import {ComponentClass} from '@shared-frontend/lib/react';
import {getWindowUnsafe} from '@shared-frontend/window';

export function initializeTracker(gaId: string): void {
  ReactGA.initialize(gaId, {nonce: getWindowUnsafe().NONCE_ID});
}

export const withTracker = <P extends PathRouteProps>(
  WrappedComponent: ComponentType<P>
): ComponentClass => {
  const ComponentWithTracker: FC<P> = props => {
    const {path} = props;
    useEffect(() => {
      if (path !== undefined) {
        ReactGA.send({hitType: 'pageview', path});
      }
    }, [path]);

    return <WrappedComponent {...props} />;
  };
  ComponentWithTracker.displayName = `withTracker(${WrappedComponent.displayName})`;
  return ComponentWithTracker;
};
