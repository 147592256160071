// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./typings/all_frontend_typings.d.ts" />

import {FC} from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {DataStoreContext} from '@shared/frontends/data_store_api';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {SharedCssReset} from '@shared/frontends/shared_css_reset';
import {GlobalStyle} from '@shared/frontends/shared_global_styles';
import {ThemeContext} from '@shared/frontends/theme_context';
import {SsrContext} from '@shared/frontends/use_ssr_context';
import {deepMerge} from '@shared/lib/object_utils';
import {addPrefix} from '@shared/lib/type_utils';

import {Analytics} from '@shared-frontend/components/analytics';
import {
  NotificationOptions,
  setupNotifications,
} from '@shared-frontend/components/core/notifications';
import {setupServiceWorker} from '@shared-frontend/setup_service_worker';
import {getWindowUnsafe} from '@shared-frontend/window';

interface FrontendConfiguration {
  appComponent: FC;
  theme: FrontendTheme;
  notifications?: Partial<NotificationOptions>;
}

export function setupFrontend(configuration: FrontendConfiguration): void {
  const {notifications, appComponent: App, theme} = configuration;
  const augmentedTheme = deepMerge(theme, getWindowUnsafe().THEME_OVERRIDES ?? {}) as FrontendTheme;
  setupNotifications(notifications);
  const container = document.getElementById('app');
  setupServiceWorker();
  if (container) {
    const app = (
      <SsrContext.Provider
        value={{
          host: document.location.host,
          initialUrl: document.location.href,
          initialSession: getWindowUnsafe().initialSession,
          initialScreenWidth: window.innerWidth,
          userAgent: navigator.userAgent,
          setSeo: ({title}) => {
            document.title = title;
          },
          setStatusCode: () => {},
        }}
      >
        <DataStoreContext.Provider value={getWindowUnsafe().DATA_STORE}>
          <ThemeContext.Provider value={augmentedTheme}>
            <SharedCssReset />
            <GlobalStyle {...addPrefix(augmentedTheme.main, '$')} />
            <BrowserRouter>
              <Analytics />
              <App />
            </BrowserRouter>
          </ThemeContext.Provider>
        </DataStoreContext.Provider>
      </SsrContext.Provider>
    );
    if (getWindowUnsafe().ssr) {
      hydrateRoot(container, app);
    } else {
      createRoot(container).render(app);
    }
  }
}
